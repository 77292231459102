// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//

import {Controller} from "stimulus"


function replaceTargetWith( target, html ){
  /// find our target
  var i, div, elm, last;
  /// create a temporary div
  div = document.createElement('div');
  /// fill that div with our html, this generates our children
  div.innerHTML = html;
  /// step through the temporary div's children and insertBefore our target
  i = div.childNodes.length;
  /// the insertBefore method was more complicated than I first thought so I
  /// have improved it. Have to be careful when dealing with child lists as they
  /// are counted as live lists and so will update as and when you make changes.
  /// This is why it is best to work backwards when moving children around,
  /// and why I'm assigning the elements I'm working with to `elm` and `last`
  last = target;
  while(i--){
    target.parentNode.insertBefore((elm = div.childNodes[i]), last);
    last = elm;
  }
  /// remove the target.
  target.parentNode.removeChild(target);
}

export default class extends Controller {
  static targets = ['container'];

  append(event) {
    const [data, status, xhr] = event.detail;
    //this.containerTarget.innerHTML = xhr.response;

    var d = document.createElement('div');
    d.innerHTML = xhr.response;
    //return d.firstChild;
    console.log(d)
    console.log(d.firstChild)

    //this.containerTarget.parentNode.replaceChild(this.containerTarget, d.firstChild);

    replaceTargetWith(this.containerTarget, xhr.response)
  }
}
