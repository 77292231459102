// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Tailwind CSS
// require("stylesheets/application")
import "stylesheets/application"

require("jquery");
window.$ = window.jQuery = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

require("trix")
require("@rails/actiontext")


import Gantt from 'frappe-gantt'
import {Chart} from "frappe-charts/dist/frappe-charts.min.esm"

$(document).on('turbolinks:load', function () {

  $(".interactive-data-grid select").on('change', function (e) {
    $(this).parents('form').submit();// filter
  });

  // var tasks = [
  //   {
  //     id: 'Task 1',
  //     name: 'Redesign website',
  //     start: '2016-12-28',
  //     end: '2016-12-31',
  //     progress: 20,
  //     dependencies: 'Task 2, Task 3',
  //     custom_class: 'bar-milestone' // optional
  //   },
  //
  // ]
  //



  if (crm.gantt && crm.gantt.length && document.getElementById('gantt')) {
    console.log(crm.gantt);
    var gantt = new Gantt("#gantt", crm.gantt, {
      header_height: 50,
      column_width: 30,
      step: 24,
      view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
      bar_height: 35,
      bar_corner_radius: 3,
      arrow_curve: 5,
      padding: 18,
      view_mode: 'Month',
      date_format: 'YYYY-MM-DD',
      custom_popup_html: null
    });

    $(".group-gantt-period").on("click", "button", function () {
      var $btn = $(this);
      var mode = $btn.data('mode');
      gantt.change_view_mode(mode);
      $btn.parent().find('button').removeClass('active');
      $btn.addClass('active');
    });
  }

  if (crm.heatmap && document.getElementById('heatmap')) {
    crm.heatmap.start = new Date(crm.heatmap.start);
    crm.heatmap.end = new Date(crm.heatmap.end);

    console.log(crm.heatmap);
    let chart = new Chart("#heatmap", {
      type: 'heatmap',
      data: crm.heatmap,
    })
  }



});
