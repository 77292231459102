// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//

import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['label', 'labelValue', 'interactive', 'input'];

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
    this.oldValue = this.value;
  }

  change_value(event){
    if (this.value === this.oldValue) {
      this.toggle()
    } else {

      let model = this.data.get("model")
      let field = this.data.get("field")
      let data = { id: this.data.get("object_id") };
      data[model] = {};
      data[model][field] = this.value;

      $.ajax({
        url: this.data.get("url"),
        data: data,
        dataType: 'json',
        type: 'PATCH',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        error: () => {
          var errorMsg = 'Error: Update Unsuccessful';
          console.log(errorMsg);
        },
        success: response => {
          this.labelValueTarget.innerHTML = this.value;
        },
        complete: () => {
          this.toggle();
        },
      });
    }
  }

  toggle(){
    this.labelTarget.classList.toggle(this.toggleClass)
    this.interactiveTarget.classList.toggle(this.toggleClass)
  }

  get value() {
    return this.inputTarget.value
  }
}
