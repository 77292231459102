//
// https://github.com/github/check-all
//
// <div data-controller="check-all">
//   <div>Count: <span data-target="check-all.count">0</span></div>
//   <label><input type="checkbox" data-target="check-all.all"> Check All</label>
//   <label><input type="checkbox"> github/fetch</label>
//   <label><input type="checkbox"> github/textarea-autosize</label>
//   <label><input type="checkbox"> github/eventlistener-polyfill</label>
//   <label><input type="checkbox"> github/quote-selection</label>
//   <label><input type="checkbox" data-skip> skip-this-one</label>
// </div>

import { Controller } from "stimulus"
import checkAll from "@github/check-all" // yarn add @github/check-all

export default class extends Controller {
  static targets = ["all", "count"]

  connect() {
    this._setAttributes()
    this.subscription = checkAll(this.element)
    // console.log(this.element)
  }

  disconnect() {
    this.subscription.unsubscribe()
    this.subscription = null
  }

  _setAttributes() {
    if (this.hasAllTarget) {
      this.allTarget.setAttribute('data-check-all', '')
    }
    if (this.hasCountTarget) {
      this.countTarget.setAttribute('data-check-all-count', '')
    }

    const selector = 'input[type="checkbox"]:not([data-check-all]):not([data-skip])'
    const checkboxes = this.element.querySelectorAll(selector)
    checkboxes.forEach(e => e.setAttribute('data-check-all-item', ''))
  }
}
